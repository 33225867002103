import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteTransaction from "../scenes/transactions/deleteTransaction";

export default function DeleteTransactionDialog(props) {
  const {
    openDeleteTransactionDialog,
    handleClose,
    clients,
    stores,
    recipients,
    storeProviders,
    transactions,
    transactionInfo,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
  } = props;

  return (
    <div>
      <Dialog
        open={openDeleteTransactionDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DeleteTransaction
            openEditTransactionDialog={openDeleteTransactionDialog}
            handleClose={handleClose}
            stores={stores}
            clients={clients}
            recipients={recipients}
            storeProviders={storeProviders}
            transactions={transactions}
            transactionInfo={transactionInfo}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
