import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Remessas from "./scenes/transactions";
import Providers from "./scenes/providers";
import Reports from "./scenes/reports";

import { useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Register from "./scenes/register/register";
import Login from "./scenes/register/login";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { storeContext } from "./context/storeContext";
import Beneficiarios from "./scenes/Recipients";
import ClientsTab from "./scenes/Clients/clients_tab";
import SettingsTab from "./scenes/settings";
import AdminTab from "./scenes/admin";
import PricingPage from "./scenes/register/pricingPage";

function App() {
  const [theme, colorMode] = useMode();
  const [storesList, setStoresList] = useState([]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <storeContext.Provider value={[storesList, setStoresList]}>
            <Routes>
              <Route path="/register/:price_id" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/checkout" element={<PricingPage />} />

              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}>
                  <Route
                    path="/"
                    element={
                      <>
                        <Sidebar />
                        <Dashboard />
                      </>
                    }
                  />
                  <Route
                    path="/remessas"
                    element={
                      <>
                        <Sidebar />
                        <Remessas />
                      </>
                    }
                  />
                  <Route
                    path="/clients"
                    element={
                      <>
                        <Sidebar />
                        <ClientsTab />
                      </>
                    }
                  />
                  <Route
                    path="/recipient"
                    element={
                      <>
                        <Sidebar />
                        <Beneficiarios />
                      </>
                    }
                  />
                  <Route
                    path="/reports"
                    element={
                      <>
                        <Sidebar />
                        <Reports />
                      </>
                    }
                  />

                  <Route
                    path="/providers"
                    element={
                      <>
                        <Sidebar />
                        <Providers />
                      </>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <>
                        <Sidebar />
                        <SettingsTab />
                      </>
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <>
                        <Sidebar />
                        <AdminTab />
                      </>
                    }
                  />
                </Route>
              </Route>
            </Routes>
          </storeContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
