import { Box, useTheme, Button, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/NoRowsOverlay";
import NewTransactionDialog from "../../components/NewTransactionDialog";
import EditTransactionDialog from "../../components/EditTransactionDialog";
import DeleteTransactionDialog from "../../components/DeleteTransactionDialog";
import TransactionMiniReportDialog from "../../components/TransactionMiniReportDialog";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import dayjs from "dayjs";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import Toast from "../../components/Snackbar";

import { styled } from "@mui/material/styles";

const Transactions = () => {
  const axiosPrivate = useAxiosPrivate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [loadingData, setLoadingData] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [storeProviders, setStoreProviders] = useState([]);
  const [stores, setStores] = useState([]);
  const [clients, setClients] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [transactionInfo, setTransactionInfo] = useState({});

  const [openNewTransactionDialog, setOpenNewTransactionDialog] =
    useState(false);
  const [openEditTransactionDialog, setOpenEditTransactionDialog] =
    useState(false);
  const [openDeleteTransactionDialog, setOpenDeleteTransactionDialog] =
    useState(false);
  const [openTransactionMiniReportDialog, setOpenTransactionMiniReportDialog] =
    useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [forceLoadClients, setForceLoadClients] = useState(false);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const response = await axiosPrivate.get("/transactions/storeChain");
        setTransactions(response.data);
        setLoadingData(false);
      } catch (err) {}
    };
    getTransactions();
  }, [
    openNewTransactionDialog,
    openEditTransactionDialog,
    openDeleteTransactionDialog,
  ]);

  useEffect(() => {
    const getStoreProviders = async () => {
      try {
        const response = await axiosPrivate.get("/storeProvider/storeChain");
        setStoreProviders(response.data);
      } catch (err) {}
    };
    getStoreProviders();
  }, []);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await axiosPrivate.get("/stores");
        setStores(response.data);
      } catch (err) {}
    };

    getStores();
  }, []);

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await axiosPrivate.get("/clients");
        setClients(response.data);
      } catch (err) {}
    };

    getClients();
  }, [forceLoadClients]);

  useEffect(() => {
    const getRecipients = async () => {
      try {
        const response = await axiosPrivate.get("/recipients");
        setRecipients(response.data);
      } catch (err) {}
    };

    getRecipients();
  }, [forceLoadClients]);

  const newTransaction = () => {
    setOpenNewTransactionDialog(true);
  };

  const handleClose = () => {
    setOpenNewTransactionDialog(false);
    setOpenEditTransactionDialog(false);
    setOpenDeleteTransactionDialog(false);
  };

  const handleRowClick = (e) => {
    setTransactionInfo(
      transactions.find((transaction) => transaction.id === e.id)
    );
    setOpenTransactionMiniReportDialog(true);
  };

  const handleEditClick = (id) => () => {
    setTransactionInfo(
      transactions.find((transaction) => transaction.id === id)
    );
    setOpenEditTransactionDialog(true);
  };

  const handleDeleteClick = (id) => () => {
    setTransactionInfo(
      transactions.find((transaction) => transaction.id === id)
    );
    setOpenDeleteTransactionDialog(true);
  };

  const handleCloseTransactionMiniReportDialog = () => {
    setOpenTransactionMiniReportDialog(false);
  };

  const columns = [
    {
      field: "client",
      headerName: "Cliente",
      flex: 1,
      valueGetter: (params) => {
        return params.row.client ? params.row.client.name : "Desconhecido";
      },
    },
    {
      field: "recipient",
      headerName: "Beneficiário",
      flex: 1,
      valueGetter: (params) => {
        return params.row.recipient
          ? params.row.recipient.name
          : "Desconhecido";
      },
    },

    {
      field: "amount",
      headerName: "Valor",
      flex: 1,
      type: "number",
      valueFormatter: (params) => {
        return `US$ ${params.value.toFixed(2)}`;
      },
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "dateTransaction",
      headerName: "Data",
      flex: 1,
      valueGetter: (params) => {
        return dayjs(params.row.dateTransaction).format("YYYY-MM-DD");
      },
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "provider",
      headerName: "Provedora",
      flex: 1,
      valueGetter: (params) => {
        return params.row.storeProvider
          ? params.row.storeProvider.Provider.name
          : "Desconhecido";
      },
    },
    {
      field: "store",
      headerName: "Loja",
      valueGetter: (params) => {
        return params.row.store ? params.row.store.name : "Desconhecido";
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Opções",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#9f1853",
    "&:hover": {
      backgroundColor: "#d9528d",
    },
  }));

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 1,
          display: "flex",
          gap: "10px",
        }}
      >
        <GridToolbarQuickFilter
          placeholder="Busque por Cliente, Beneficiário, Loja ou Provedora"
          variant="outlined"
          style={{ flex: 0.5 }}
        />
        <ColorButton
          variant="contained"
          onClick={newTransaction}
          sx={{ gridColumn: "span 2" }}
        >
          <AddCircleOutlinedIcon sx={{ mr: "10px" }} />
          <Typography fontWeight="bold">NOVO</Typography>
        </ColorButton>
      </Box>
    );
  }

  return (
    <Box marginLeft="280px">
      <Box m="20px">
        <Header
          title="Remessas"
          subtitle="Gerencie suas Remessas"
          color="#9f1853"
        ></Header>

        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: "#0c1c3c",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#9f1853",
              borderBottom: "none",
              color: "white",
              maxHeight: "168px !important",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#fff",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !Important`,
            },
            "& .super-app-theme--cell": {
              backgroundColor: "rgba(224, 183, 60, 0.55)",
              color: "#1a3e72",
              fontWeight: "600",
            },
            "& .super-app.positive": {
              backgroundColor: "rgba(157, 255, 118, 0.49)",
              color: "#1a3e72",
              fontWeight: "600",
            },
            "& .super-app.negative": {
              backgroundColor: "#d47483",
              color: "#1a3e72",
              fontWeight: "600",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              height: "unset !important",
            },
          }}
        >
          <DataGrid
            loading={loadingData}
            initialState={{
              sorting: {
                sortModel: [{ field: "dateTransaction", sort: "desc" }],
              },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[25, 50, 100]}
            getRowId={(row) => row.id}
            onRowClick={(e) => handleRowClick(e)}
            rows={transactions}
            columns={columns}
            slots={{
              toolbar: QuickSearchToolbar,
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            disableColumnMenu
          />
        </Box>
        <Toast
          open={openAlert}
          alertMessage={alertMessage}
          setOpen={setOpenAlert}
          severity={severity}
        />
        <NewTransactionDialog
          openNewTransactionDialog={openNewTransactionDialog}
          handleClose={handleClose}
          stores={stores}
          clients={clients}
          recipients={recipients}
          storeProviders={storeProviders}
          transactions={transactions}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}
          forceLoadClients={forceLoadClients}
          setForceLoadClients={setForceLoadClients}
        />
        <EditTransactionDialog
          openEditTransactionDialog={openEditTransactionDialog}
          handleClose={handleClose}
          stores={stores}
          clients={clients}
          recipients={recipients}
          storeProviders={storeProviders}
          transactions={transactions}
          transactionInfo={transactionInfo}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}
        />
        <DeleteTransactionDialog
          openDeleteTransactionDialog={openDeleteTransactionDialog}
          handleClose={handleClose}
          stores={stores}
          clients={clients}
          recipients={recipients}
          storeProviders={storeProviders}
          transactions={transactions}
          transactionInfo={transactionInfo}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}
        />
        <TransactionMiniReportDialog
          openTransactionMiniReportDialog={openTransactionMiniReportDialog}
          handleClose={handleCloseTransactionMiniReportDialog}
          transactions={transactions}
          transactionInfo={transactionInfo}
          stores={stores}
          clients={clients}
          storeProviders={storeProviders}
          recipients={recipients}
        />
      </Box>
    </Box>
  );
};
export default Transactions;
