import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteStoreProvider from "../scenes/providers/deleteStoreProvider";

export default function DeleteStoreProviderDialog(props) {
  const {
    openDeleteStoreProviderDialog,
    handleClose,
    provider_info,
    stores,
    providers,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  return (
    <div>
      <Dialog
        open={openDeleteStoreProviderDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DeleteStoreProvider
            provider_info={provider_info}
            handleClose={handleClose}
            stores={stores}
            providers={providers}
            setAlertMessage={setAlertMessage}
            setOpenAlert={setOpenAlert}
            setSeverity={setSeverity}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
