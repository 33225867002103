import useAxiosPrivate from "../../api/useAxiosPrivate";
import { Button, useTheme } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useState } from "react";

const UploadButtonLP = (props) => {
  const theme = useTheme();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const {
    file,
    store,
    provider,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    handleClose,
  } = props;

  const findOrCreateClient = async (info) => {
    try {
      const response = await axiosPrivate.post(
        "/clients/findCreate",
        JSON.stringify(info),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );
      return response.data;
    } catch (err) {}
  };

  const findOrCreateRecipient = async (info) => {
    try {
      const response = await axiosPrivate.post(
        "/recipients/findCreate",
        JSON.stringify(info),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );
      return response.data;
    } catch (err) {}
  };

  const findClientLP = async (file) => {
    let clientErrors = 0;
    let clientCreated = 0;
    let clientFound = 0;

    for (const element of file) {
      if (!element.sender || !element.senderPhone) {
        clientErrors += 1;
      } else {
        const clientInfo = {
          name: element.sender,
          phoneNumber: element.senderPhone,
        };
        const client = await findOrCreateClient(clientInfo);
        element.clientId = client.client.id;
        client.created ? (clientCreated += 1) : (clientFound += 1);
      }
    }
    return file;
  };

  const findRecipientLP = async (file) => {
    for (const element of file) {
      if (!element.recipient || !element.clientId) {
      } else {
        const recipientInfo = {
          name: element.recipient,
          clientId: element.clientId,
        };
        const recipient = await findOrCreateRecipient(recipientInfo);
        element.recipientId = recipient.recipient.id;
      }
    }
    return file;
  };

  const createLoad = async (data) => {
    const load = [];
    for (const transaction of data) {
      const remessa = {
        dateTransaction: transaction.dateTransaction,
        storeId: store.id,
        destinyCountry: transaction.destinyCountry,
        storeProviderId: provider.id,
        amount: Number(transaction.amount.replace(",", "")),
        fee: transaction.fee,
        profit: transaction.profit,
        clientId: transaction.clientId,
        recipientId: transaction.recipientId,
        paymentMethod: "NAO INFORMADO",
        quotationProvider: 0,
        quotationClient: 0,
      };
      load.push(remessa);
    }
    console.log(load);
    return load;
  };

  const upload = async (file) => {
    setLoading(true);
    let data1 = await findClientLP(file);
    let data2 = await findRecipientLP(data1);
    let load = await createLoad(data2);

    try {
      const response = await axiosPrivate.post(
        "/transactions/bulkCreate",
        JSON.stringify(load),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 201) {
        setAlertMessage(`${response.data.length} Transações foram criadas`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro das Remessas");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  return (
    <Button
      disabled={loading}
      variant="contained"
      component="label"
      startIcon={<CloudUploadOutlinedIcon />}
      onClick={() => upload(file)}
    >
      {loading ? "CARREGANDO" : `IMPORTAR ${file.length} REMESSA(S)`}
    </Button>
  );
};
export default UploadButtonLP;
