import { styled } from "@mui/material/styles";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const DELETE_CLIENT_URL = "/clients/";
const today = dayjs;

const DeleteClient = (props) => {
  const {
    handleClose,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    clientInfo,
  } = props;
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const name = clientInfo.name;
  const phoneNumber = clientInfo.phoneNumber;
  const birthDate = clientInfo.birthDate ? dayjs(clientInfo.birthDate) : null;
  const email = clientInfo.email;
  const address = clientInfo.address;
  const city = clientInfo.city;
  const state = clientInfo.state;
  const zipCode = clientInfo.zipCode;
  const country = clientInfo.country;
  const documentType = clientInfo.documentType;
  const documentExpiration = clientInfo.documentExpiration
    ? dayjs(clientInfo.documentExpiration)
    : null;
  const passportNumber = clientInfo.passportNumber;
  const passportExpiration = clientInfo.passportExpiration
    ? dayjs(clientInfo.passportExpiration)
    : null;
  const driverLicense = clientInfo.driverLicense;
  const driverLicenseExpiration = clientInfo.driverLicenseExpiration
    ? dayjs(clientInfo.driverLicenseExpiration)
    : null;
  const proofFundsExpiration = clientInfo.proofFundsExpiration
    ? dayjs(clientInfo.proofFundsExpiration)
    : null;

  const socialSecurityNumber = clientInfo.socialSecurityNumber;
  const individualTaxpayerIdentificationNumber =
    clientInfo.individualTaxpayerIdentificationNumber;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    try {
      const response = await axiosPrivate.delete(
        `${DELETE_CLIENT_URL}${clientInfo.id}`,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setAlertMessage(`Cliente Removido!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro do Cliente");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Box>
        <Header
          title="REMOVER CLIENTE"
          subtitle="ATENÇÃO: Ao remover um cliente todos os seus beneficiários e remessas também serão excluídos"
          button={false}
          color="#f04646"
        />
        <Box
          sx={{
            borderRadius: 3,
            autoComplete: "off",
            padding: 3,
            gap: 2,
            bgcolor: colors.primary[400],
            display: "grid",
            gridTemplateColumns: "repeat(8, 1fr)",
          }}
        >
          <TextField
            id="name"
            disabled
            label="Nome"
            variant="outlined"
            value={name}
            required
            sx={{ gridColumn: isNonMobile ? "span 5" : "span 8" }}
          />
          <TextField
            disabled
            id="phoneNumber"
            label="Telefone"
            variant="outlined"
            value={[phoneNumber]}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 8" }}
          />
          <TextField
            disabled
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            sx={{ gridColumn: isNonMobile ? "span 5" : "span 8" }}
          />
          <DatePicker
            disabled
            id="birthDate"
            variant="outlined"
            disableFuture
            label="Data de Nascimento"
            defaultValue={today}
            value={birthDate}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 8" }}
            format="YYYY-MM-DD"
            slotProps={{
              textField: {
                error: false,
              },
            }}
          />
          <TextField
            disabled
            id="address"
            label="Endereço"
            variant="outlined"
            value={address}
            sx={{ gridColumn: isNonMobile ? "span 5" : "span 8" }}
          />
          <TextField
            disabled
            id="zipCode"
            label="ZipCode"
            variant="outlined"
            value={zipCode}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
          />
          <TextField
            id="city"
            label="Cidade"
            variant="outlined"
            value={city}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
          />
          <TextField
            disabled
            id="state"
            label="Estado"
            variant="outlined"
            value={state}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          />
          <TextField
            disabled
            id="country"
            label="Pais"
            variant="outlined"
            value={country}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
          />
          <TextField
            disabled
            id="passportNumber"
            label="Número do Passaporte"
            variant="outlined"
            value={passportNumber}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
          />
          <DatePicker
            disabled
            id="passportExpiration"
            label="Validade do Passaporte"
            variant="outlined"
            value={passportExpiration}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            format="YYYY-MM-DD"
            slotProps={{
              textField: {
                error: false,
              },
            }}
          />
          <TextField
            disabled
            id="socialSecurityNumber"
            label="Social Security Number"
            variant="outlined"
            value={socialSecurityNumber}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 8" }}
          />
          <TextField
            disabled
            id="driverLicense"
            label="Número da Habilitação"
            variant="outlined"
            value={driverLicense}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
          />
          <DatePicker
            id="driverLicenseExpiration"
            label="Validade da Habilitação"
            disabled
            variant="outlined"
            value={driverLicenseExpiration}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            format="YYYY-MM-DD"
            slotProps={{
              textField: {
                error: false,
              },
            }}
          />

          <TextField
            disabled
            id="individualTaxpayerIdentificationNumber"
            label="Individual Taxpayer Identification Number"
            variant="outlined"
            value={individualTaxpayerIdentificationNumber}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 8" }}
          />

          {clientInfo.documentURL && (
            <>
              <Divider variant="middle" sx={{ gridColumn: "span 8" }} />
              <Button
                href={clientInfo.documentURL}
                variant="contained"
                startIcon={<CloudDownloadOutlinedIcon />}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 8" }}
              >
                BAIXAR DOCUMENTO
              </Button>
              {isNonMobile && <Box sx={{ gridColumn: "span 2" }} />}
              <TextField
                id="documentType"
                disabled
                label="Tipo do Documento"
                variant="outlined"
                value={documentType}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
              />
              <DatePicker
                id="documentExpiration"
                disabled
                label="Validade do Documento"
                variant="outlined"
                value={documentExpiration}
                format="YYYY-MM-DD"
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
                slotProps={{
                  textField: {
                    error: false,
                  },
                }}
              />
            </>
          )}

          {clientInfo.proofFundsURL && (
            <>
              <Divider variant="middle" sx={{ gridColumn: "span 8" }} />

              <Button
                href={clientInfo.proofFundsURL}
                variant="contained"
                startIcon={<CloudDownloadOutlinedIcon />}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 8" }}
              >
                BAIXAR PROVA DE FUNDOS
              </Button>

              {<Box sx={{ gridColumn: "span 4" }} />}

              <DatePicker
                id="proofFundsExpiration"
                label="Validade da Prova de Fundos"
                disabled
                variant="outlined"
                value={proofFundsExpiration}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
                format="YYYY-MM-DD"
                slotProps={{
                  textField: {
                    error: false,
                  },
                }}
              />
            </>
          )}
          <Divider variant="middle" sx={{ gridColumn: "span 8" }} />

          {isNonMobile && <Box sx={{ gridColumn: "span 4" }} />}

          <Button
            type="submit"
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Typography fontWeight="bold">Remover Cliente</Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Typography fontWeight="bold">Cancelar</Typography>
          </ColorButton>
        </Box>
      </Box>
    </Box>
  );
};
export default DeleteClient;
