import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box , useTheme} from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import StoreDialog from './StoresDialog';
import ClientDialog from './ClientDialog';
import NewRecipientDialog from './NewRecipientDialog';
import NewTransactionDialog from './NewTransactionDialog';

import { useState } from 'react';


export default function BotaoNovo() {

const [openStoreDialog, setOpenStoreDialog] = useState(false)
const [openNewClientDialog, setOpenNewClientDialog] = useState(false)
const [openNewTransactionDialog, setOpenNewTransactionDialog] = useState(false)
const [openNewRecipientDialog, setOpenNewRecipientDialog] = useState(false)


const newTransaction = () =>{ 
  setOpenNewTransactionDialog(true)
}

const newStore = () => {
  setOpenStoreDialog(true)
}

const newClient = () =>{ 
 setOpenNewClientDialog(true)
}

const newRecipient = () =>{
  setOpenNewRecipientDialog(true)
}

const handleClose = () => {
  setOpenStoreDialog(false)
  setOpenNewClientDialog(false)
  setOpenNewRecipientDialog(false)
  setOpenNewTransactionDialog(false)
}


const theme = useTheme();

const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseButton = () => {
    setAnchorEl(null);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  }

  return (
    <Box>
              <Button
                sx={[{
                    backgroundColor: "#0c1c3c",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                }, 
                {
                  '&:hover': {
                    color: 'white',
                    backgroundColor: '#0d3a87',
                  }}]}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                ria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
            <AddCircleOutlinedIcon sx={{mr: "10px"}} />
                Novo
        </Button>
  

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseButton}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={newTransaction}>Remessa</MenuItem>
        <MenuItem onClick={newStore}>Loja</MenuItem>
        <MenuItem onClick={newClient}>Cliente</MenuItem>
        <MenuItem onClick={newRecipient}>Beneficiario</MenuItem>
      </Menu>

      <StoreDialog openStoreDialog={openStoreDialog} handleClose={handleClose} />
      <ClientDialog openNewClientDialog={openNewClientDialog} handleClose={handleClose} />
      <NewRecipientDialog openNewRecipientDialog={openNewRecipientDialog} handleClose={handleClose} />
      <NewTransactionDialog openNewTransactionDialog={openNewTransactionDialog} handleClose={handleClose} />
    </Box>


  );
      }