import { Box, Button, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {GridToolbarQuickFilter, DataGrid , GridActionsCellItem} from "@mui/x-data-grid";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { tokens } from "../../theme";
import ClientMiniReportDialog from "../../components/ClientMiniReportDialog";
import ClientDialog from "../../components/ClientDialog"
import EditClientDialog from "../../components/EditClientDialog"
import DeleteClientDialog from "../../components/DeleteClientDialog";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import Toast from "../../components/Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/NoRowsOverlay";

import { styled } from "@mui/material/styles";



const Clients = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const axiosPrivate = useAxiosPrivate()
    const [clients, setClients] = useState([])
    const [client_info, setClientInfo] = useState({})

    const [loadingData, setLoadingData] = useState(true)
    const [transactions, setTransactions] = useState([])
    const [recipients, setRecipients] = useState([])
    const [openNewClientDialog, setOpenNewClientDialog] = useState(false)
    const [openEditClientDialog, setOpenEditClientDialog] = useState(false)
    const [openDeleteClientDialog, setOpenDeleteClientDialog] = useState(false)
    const [openClientMiniReportDialog, setOpenClientMiniReportDialog] = useState(false)

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [severity, setSeverity] = useState("");

    useEffect(() => {
      const getClients = async () => {
        try {
          const response = await axiosPrivate.get("/clients");
          setClients(response.data);
          setLoadingData(false);
        } catch (err) {}
      };
  
      getClients();
    }, [openEditClientDialog, openNewClientDialog, openDeleteClientDialog]);

     const handleCloseDialog = () => {
      setOpenEditClientDialog(false)
      setOpenClientMiniReportDialog(false)
      setOpenNewClientDialog(false)
      setOpenDeleteClientDialog(false)
  }
    
    const newClient = () =>{ 
        setOpenNewClientDialog(true)
       }


    const handleEditClick = (id) => () => {
      setClientInfo(clients.find(client => client.id === id))
      setOpenEditClientDialog(true)
    };
    const handleDeleteClick = (id) => () => {
      setClientInfo(clients.find(client => client.id === id))
      setOpenDeleteClientDialog(true)
    };

  const handleRowClick = (e) => {
    setClientInfo(clients.find(client => client.id === e.id))
    setOpenClientMiniReportDialog(true)
  }


    const columns = [
        {
            field: "name",
            headerName:"Nome",
            flex: 1,
            cellClassName: "name-column--cell"},
        
        {
            field: "phoneNumber",
            headerName:"Telefone",
            flex: 1
        },
        {
            field: "city",
            headerName: "Cidade",
            flex: 1,
            getApplyQuickFilterFn: undefined
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Opções',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => {
    
            
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
              />,
            ];
          },
        },
    ]

    const ColorButton = styled(Button)(() => ({
      color: "white",
      backgroundColor: "#004549",
      "&:hover": {
        backgroundColor: "#336a6d",
      },
    }));

    function QuickSearchToolbar() {
        return (
          <Box
            sx={{
              p: 0.5,
              pb: 1,
              display: 'flex',
              gap: "10px"
            }}
          >   
            <GridToolbarQuickFilter placeholder="Busque por Nome ou Telefone" variant="outlined" style={{flex: .5}}/>
            <ColorButton
              variant="contained"
              onClick={newClient}
              sx={{ gridColumn: "span 2" }}
            >
              <AddCircleOutlinedIcon sx={{mr: "10px"}} />
              <Typography fontWeight="bold">NOVO</Typography>
            </ColorButton>

          </Box>
        );
      }
      
    return (
        
        <Box height="75vh" sx={{
            "& .MuiDataGrid-root": {
                border: "none"
            },
            "& .MuiDataGrid-cell": {
                borderBottom: "none"
            },
            "& .name-column--cell": {
                color: "#0c1c3c"
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#004549",
                borderBottom: "none",
                color: "white"
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400]
            },
            "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#fff",
                
            }
            }}>
      <DataGrid
        loading={loadingData}
        getRowId={(row) => row.id}
        onRowClick={(e) => handleRowClick(e)}
        columns={columns}
        rows={clients}
        rowThreshold={0}
        initialState={{pagination: {paginationModel: {pageSize: 25}}, }} pageSizeOptions={[25, 50, 100]}
        slots={{ toolbar: QuickSearchToolbar, loadingOverlay: LinearProgress, noRowsOverlay: CustomNoRowsOverlay}}
        slotProps={{
          toolbar: {
          showQuickFilter: true,
          },
        }}
        pagination
        disableColumnMenu        
      />
      
      <Toast
        open={openAlert}
        setOpen={setOpenAlert}
        alertMessage={alertMessage}
        severity={severity}
      />
       <ClientDialog openNewClientDialog={openNewClientDialog} handleClose={handleCloseDialog} setAlertMessage={setAlertMessage} setSeverity={setSeverity} setOpenAlert={setOpenAlert}/>
       <EditClientDialog openEditClientDialog={openEditClientDialog} handleClose={handleCloseDialog} clientInfo={client_info} setAlertMessage={setAlertMessage} setSeverity={setSeverity} setOpenAlert={setOpenAlert}/>
       <DeleteClientDialog openDeleteClientDialog={openDeleteClientDialog} handleClose={handleCloseDialog} clientInfo={client_info} setAlertMessage={setAlertMessage} setSeverity={setSeverity} setOpenAlert={setOpenAlert}/>
       <ClientMiniReportDialog openClientMiniReportDialog={openClientMiniReportDialog} handleClose={handleCloseDialog} clientInfo={client_info}/>
       </Box>
    )

}

export default Clients;