import { Typography, Box, useTheme, Paper, Grid } from "@mui/material";
import BasicMenu from "./BotaoNovo";

const Header = ({title, subtitle, button=false, color='#0c1c3c', textColor="white"}) => {
    return (
        <>
        <Paper elevation={3} width={"80%"} sx={{"background": color}}>
        <Box display='flex'margin="5px" p="5px" justifyContent="space-between" elevation={6} spacing={2} width={"100%"}>
        <Box>
            <Typography
             variant="h1"
             color = {textColor}
             fontWeight="bold"
            >
                {title}
            </Typography>

            <Typography
            gutterBottom
            variant="h5"
            color = {textColor
            }
            fontWeight="bold">{subtitle}</Typography>
        </Box>
      
    <Box display="flex" alignItems={"center"} marginRight="10px">
        {button && <BasicMenu />}
        </Box>
        </Box>
    </Paper>
    <Box height="1em"/>
    </>
    );
}

export default Header;