import { useState, useEffect } from "react";
import useAxiosPrivate from "../api/useAxiosPrivate.js";

const ListStores = () => {
  const [stores, setStores] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getStores = async () => {
      try {
        const response = await axiosPrivate.get("/stores", {
          signal: controller.signal,
        });
        isMounted && setStores(response.data);
        //console.log(response.data.lojasAutorizadas);
      } catch (err) {
        //console.log(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getStores();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return stores?.length ? stores : [];
};

export default ListStores;
