import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

const PieChart = ( props ) => {
    const {data, dolar} = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let USDollar = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2  })
    return (
       <ResponsivePie
           data={data}
           theme = {{
               axis: {
                   domain: {
                       line: {
                           stroke: colors.grey[100]
                       }
                   },
                   legend: {
                       text: {
                           fill: colors.grey[100]
                       }
                   },
                   ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1
                        },
                        text: {
                            fill: colors.grey[100]
                        }
                   }
               },
               legends: {
                text: {
                    fill: colors.grey[100]
                }
               }
           }}
           legends={[
            {
              anchor: "center",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 120,
              itemsSpacing: 0,
              itemDirection: "top-to-bottom",
              itemWidth: 110,
              itemHeight: 20,
              itemOpacity: 1,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
            },
          ]}
           margin={{ top: 20, right: 20, bottom: 60, left: 20 }}
           id='providerId'
           //colors={["#52B7D8", "#E16032", "#FFB03B", "#54A77B", "#4FD2D2", "#E287B2"]}
          colors={{datum: 'data.color'}}
           valueFormat={dolar && (value => `${USDollar.format(value)}`)}
           animate={true}
           innerRadius={0.5}
           padAngle={0.7}
           cornerRadius={2}
           activeOuterRadiusOffset={6}
           borderWidth={1}
           borderColor={{
               from: 'color',
               modifiers: [
                   [
                       'darker',
                       0.2
                   ]
               ]
           }}
           enableArcLinkLabels={false}
          
           
           tooltip={({ datum: { id, value, color, data } }) => (
            <div
                style={{
                    padding: 12,
                    background: color,
                }}
            >
                <strong>
                    {data.label}<br/>
                    {dolar && (`USD ${USDollar.format(value)}`)}
                    {!dolar && (value)}
                </strong>
            </div>
        )}
       />
    )
}

export default PieChart;