import { ResponsiveLine } from "@nivo/line";
import { useState } from "react";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveLine = (props) => {
  const { dataProfit, dataAmount, selectProfit } = props;
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  return (
    <ResponsiveLine
      animate
      enableArea
      areaOpacity={0.8}
      colors={{ datum: "color" }}
      margin={{ top: 50, right: 60, bottom: 50, left: 50 }}
      axisBottom={{
        format: "%Y-%m-%d",
        legendOffset: 36,
        tickValues: "every 5 days",
        legendPosition: "middle",
      }}
      axisRight={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={null}
      data={selectProfit ? dataProfit : dataAmount}
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      pointBorderWidth={1}
      pointSize={8}
      useMesh={false}
      xFormat="time:%Y-%m-%d"
      xScale={{
        format: "%Y-%m-%d",
        precision: "day",
        type: "time",
        useUTC: false,
      }}
      enableSlices="x"
      yFormat=" >-.2f"
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      legends={[
        {
          anchor: "top-left",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: -30,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 110,
          itemHeight: 20,
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
        },
      ]}
      sliceTooltip={(props) => {
        return (
          <div
            style={{
              background: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
            }}
          >
            <strong>{`Data: ${props.slice.points[0].data.xFormatted}`}</strong>
            {props.slice.points.map((point) => (
              <div
                key={point.serieId}
                style={{
                  color: point.color,
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ height: 10, width: 10, background: point.color }}
                />
                <div style={{ width: 10 }} />

                <div />
                <div style={{ alignSelf: "start" }}>
                  <strong>{point.serieId}</strong>
                </div>
                <div style={{ width: 30 }} />
                <div>
                  <strong>{`USD ${USDollar.format(
                    point.data.yFormatted
                  )}`}</strong>
                </div>
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};

export default MyResponsiveLine;
