import * as XLSX from "xlsx";
import dayjs from "dayjs";

const handleFileAnalysisLP = (file, callbackError, callbackSuccess) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  var reader = new FileReader();
  const columns = [
    "Date & Time",
    "ID Number",
    "Transaction Status",
    "Profit",
    "USD Collected",
    "Fee",
    "Agent Fee Share",
    "USD Sent",
    "Currency",
    "Amount of Currency Sent",
    "Sender Name",
    "Sender Phone",
    "Receiver Name",
    "Adjustment to Balance",
  ];

  reader.onload = (e) => {
    var data = e.target.result;
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets["Transactions Ledger"];
    if (!worksheet) {
      callbackError("error", "Planilha não encontrada no Arquivo");
      callbackSuccess([]);
    } else {
      var range = XLSX.utils.decode_range(worksheet["!ref"]);
      range.s.r = 2;
      worksheet["!ref"] = XLSX.utils.encode_range(range);
      const result = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      const load = [];
      for (const transaction of result) {
        if (!dayjs(transaction["Date & Time"]).isValid()) {
          callbackError("error", "Planilha com datas erradas");
          callbackSuccess([]);
        } else {
          const remessa = {
            dateTransaction: dayjs(transaction["Date & Time"]).format(
              "YYYY-MM-DD"
            ),
            destinyCountry: transaction.Currency,
            sender: transaction["Sender Name"],
            senderPhone: transaction["Sender Phone"],
            recipient: transaction["Receiver Name"],
            amount: USDollar.format(Number(transaction["USD Sent"])),
            fee: USDollar.format(Number(transaction.Fee)),
            profit: USDollar.format(Number(transaction.Profit)),
            paymentMethod: "NAO INFORMADO",
          };
          load.push(remessa);
        }
      }

      callbackSuccess(load);
    }
  };
  reader.readAsArrayBuffer(file);
};

export default handleFileAnalysisLP;
