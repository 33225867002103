
import { ResponsiveBar } from "@nivo/bar"

const BarChart = (props) => {
    const {data, isDashboard, isNonMobile} = props;

    let providers = Object.keys(Object.assign({}, ...data));
    providers = providers.filter(el => (el !== 'dateTransaction' && el.slice(-5) !== 'color'))

    return(
       <ResponsiveBar
           data={data}
           keys={providers}
           indexBy="dateTransaction"
           margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
           padding={0.3}
           valueScale={{ type: 'linear' }}
           indexScale={{ type: 'band', round: true }}
           colors={({data, id}) => data[`${id}_color`]}
           //colors={["#52B7D8", "#E16032", "#FFB03B", "#54A77B", "#4FD2D2", "#E287B2"]}
           minValue={0}
           valueFormat={' >-.2f'}
        
           axisTop={null}
           axisRight={null}
           axisBottom={{
               tickSize: 5,
               tickPadding: 5,
               tickRotation: isNonMobile ? 0 : -35,
               legend: isDashboard ? undefined : 'Data Transação',
               legendPosition: 'middle',
               legendOffset: 32
           }}
           axisLeft={{
               tickSize: 5,
               tickPadding: 5,
               tickRotation: 0,
               legend: isDashboard ? undefined : 'Valor',
               legendPosition: 'middle',
               legendOffset: -40
           }}
           enableLabel={true}
           labelSkipWidth={12}
           labelSkipHeight={12}
           labelTextColor={{
               from: 'color',
               modifiers: [
                   [
                       'darker',
                       5
                   ]
               ]
           }}
           legends={[
               {
                
                   dataFrom: 'keys',
                   anchor: 'bottom-right',
                   direction: 'column',
                   justify: false,
                   translateX: 120,
                   translateY: 0,
                   itemsSpacing: 2,
                   itemWidth: 100,
                   itemHeight: 20,
                   itemDirection: 'left-to-right',
                   itemOpacity: 0.85,
                   symbolSize: 20,
                   effects: [
                       {
                           on: 'hover',
                           style: {
                               itemOpacity: 1
                           }
                       }
                   ]
               }
           ]}
           animate={true}
           role="application"
           tooltip={({ id, formattedValue, color, data }) => (
            <div
                style={{
                    padding: 12,
                    background: color,
                }}
            >
                <strong>    
                    {`USD ${formattedValue}`} <br/>
                    {`${id}`}<br/>
                    {`Data: ${data.dateTransaction}`}

                </strong>
            </div>
        )}
       />
    )
}

export default BarChart;