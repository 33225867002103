import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  TextField,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
  Autocomplete,
  Box,
} from "@mui/material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import NewBankInformationDialog from "../../components/NewBankInformationDialog";
import EditBankInformationDialog from "../../components/EditBankInformationDialog";
const EDIT_RECIPIENT_URL = "/recipients";

const EditRecipient = (props) => {
  const {
    handleClose,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    recipientInfo,
  } = props;

  const [reload, setReload] = useState(false);
  const [openEditBankInformation, setOpenEditBankInformation] = useState(false);
  const [openNewBankInformation, setOpenNewBankInformation] = useState(false);
  const handleCloseDialog = () => {
    setOpenNewBankInformation(false);
    setOpenEditBankInformation(false);
  };

  const handleOpenNewBankInformationDialog = () => {
    setOpenNewBankInformation(true);
  };

  const handleOpenEditBankInformationDialog = () => {
    setOpenEditBankInformation(true);
  };

  const [bankInformationInfo, setBankInformationInfo] = useState({});

  const pixKeyTypes = ["Celular", "CPF", "Chave Aleatória", "E-mail"];
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [bankInformations, setBankInformations] = useState([]);

  const [name, setName] = useState(recipientInfo.name);
  const [identificationDocument, setIdentificationDocument] = useState(
    recipientInfo.identificationDocument
  );

  const handleDeleteBankInformation = async (id) => {
    try {
      const response = await axiosPrivate.delete(`/bankInformation/${id}`, {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        withCredentials: true,
      });
      if (response.status === 200) {
        setReload(!reload);
        setAlertMessage("Informações Bancárias deletadas com sucesso!");
        setOpenAlert(true);
        setSeverity("success");
      } else {
        setAlertMessage("Erro ao deletar banco!");
        setOpenAlert(true);
        setSeverity("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClick = (id) => {
    setBankInformationInfo(
      bankInformations.find((bankInformation) => bankInformation.id === id)
    );
    handleOpenEditBankInformationDialog();
  };

  const handleDeleteClick = (id) => {
    handleDeleteBankInformation(id);
    setReload(!reload);
  };

  const columns = [
    {
      field: "bankName",
      headerName: "Nome do Banco",
      flex: 1,
    },

    {
      field: "bankBranch",
      headerName: "Agência",
      flex: 1,
    },

    {
      field: "bankAccount",
      headerName: "Conta",
      flex: 1,
    },

    {
      field: "pixKeyType",
      headerName: "Tipo de Chave PIX",
      flex: 1,
    },

    {
      field: "pixKey",
      headerName: "Chave PIX",
      headerName: "Chave Pix",
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Opções",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              handleEditClick(id);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => {
              handleDeleteClick(id);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    const fetchBankInformations = async () => {
      try {
        const response = await axiosPrivate.get(
          `/bankInformation/recipient/${recipientInfo.id}`,
          {
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            withCredentials: true,
          }
        );
        setBankInformations(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBankInformations();
  }, [openNewBankInformation, openEditBankInformation, reload]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    if (!name) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axiosPrivate.put(
        `${EDIT_RECIPIENT_URL}/${recipientInfo.id}`,
        JSON.stringify({
          name,
          identificationDocument,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage(`Beneficiário ${response.data.name} editado!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro do Beneficiário");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header
        title="EDITAR BENEFICIÁRIO"
        subtitle={`Edite as informações do Beneficiário - ${recipientInfo.name}`}
        button={false}
        color="#E35A01"
      />

      <Box
        borderRadius="10px"
        marginTop="20px"
        padding="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Cliente
        </Typography>

        <Box sx={{ gridColumn: "span 4" }} />

        <TextField
          fullWidth
          value={recipientInfo.client.name}
          disabled
          variant="outlined"
          id="nome-cliente"
          label="Nome do Cliente"
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 4",
          }}
        />
      </Box>

      {/* PARTE DO BENEFICIÁRO */}
      <Box
        borderRadius="10px"
        padding="20px"
        marginTop="20px"
        marginBottom="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gap="15px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          Beneficiário
        </Typography>
        <Box sx={{ gridColumn: "span 4" }} visibility="hidden" />
        <TextField
          required
          size="small"
          fullWidth
          value={name}
          variant="outlined"
          type="text"
          label="Nome"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 2" : "span 4",
          }}
          onChange={(e) => setName(e.target.value)}
          //inputProps={{ readOnly: true }}
        />
        <TextField
          required
          size="small"
          fullWidth
          value={identificationDocument}
          variant="outlined"
          type="text"
          label="Documento de Identificação"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 2" : "span 4",
          }}
          onChange={(e) => setIdentificationDocument(e.target.value)}
          //inputProps={{ readOnly: true }}
        />
      </Box>
      <Box
        borderRadius="10px"
        padding="20px"
        marginTop="20px"
        marginBottom="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold">
            Informações Bancárias
          </Typography>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary,
              width: "100px",
            }}
            id="edit-client"
            onClick={handleOpenNewBankInformationDialog}
          >
            <AddCircleOutlinedIcon sx={{ mr: "10px" }} />
            <Typography fontWeight="bold">NOVO</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: "#0c1c3c",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E35A01",
              borderBottom: "none",
              color: "white",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row.id}
            columns={columns}
            rows={bankInformations}
            rowThreshold={0}
            disableColumnMenu
            hideFooterPagination
            hideFooterSelectedRowCount
          />
        </Box>
      </Box>
      <Box
        borderRadius="10px"
        marginTop="20px"
        padding="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Button
          disabled={!name}
          type="submit"
          color="secondary"
          variant="contained"
          height="100%"
          onClick={handleSubmit}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          <Typography fontWeight="bold">Editar Beneficiário</Typography>
        </Button>
        <ColorButton
          variant="contained"
          height="100%"
          onClick={handleClose}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          Cancelar
        </ColorButton>
      </Box>
      <NewBankInformationDialog
        open={openNewBankInformation}
        handleClose={handleCloseDialog}
        recipient={recipientInfo}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <EditBankInformationDialog
        open={openEditBankInformation}
        handleClose={handleCloseDialog}
        recipient={recipientInfo}
        bankInformationInfo={bankInformationInfo}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
    </Box>
  );
};
export default EditRecipient;
