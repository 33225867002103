import { useState } from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  FormControl,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { NumericFormat } from "react-number-format";

const NEW_STORE_PROVIDER_URL = "/storeProvider";

const NewStoreProvider = (props) => {
  const {
    handleClose,
    stores,
    providers,
    storeProviders,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [store, setStore] = useState(null);
  const [provider, setProvider] = useState(null);

  const [fixedFee, setFixedFee] = useState(0);
  const [customAnnualLimit, setCustomAnnualLimit] = useState(0);
  const [customMonthlyLimit, setCustomMonthlyLimit] = useState(0);
  const [customDailyLimit, setCustomDailyLimit] = useState(0);
  const [quotationClient, setQuotationClient] = useState(0);
  const [quotationProvider, setQuotationProvider] = useState(0);
  const [providerFee, setProviderFee] = useState(0);
  const [active, setActive] = useState(true);

  const [fixedFeeLower, setLsetFixedFeeLower] = useState(0);
  const [providerFeeLower, setProviderFeeLower] = useState(0);
  const [providerFeeLowerTreshold, setProviderFeeLowerTreshold] = useState(100);
  const [feeLowerCheck, setFeeLowerChek] = useState(false);

  const handleChangeActive = (event) => {
    setActive(event.target.checked);
  };

  const handleChangeFeeLowerCheck = (event) => {
    setFeeLowerChek(event.target.checked);
  };

  const handleChangeProvider = (newValue) => {
    setProvider(newValue);
    setCustomAnnualLimit(newValue.annualLimit);
    setCustomMonthlyLimit(newValue.monthlyLimit);
    setCustomDailyLimit(newValue.dailyLimit);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var updateInfo = {};

    if (store.id) {
      updateInfo.storeId = store.id;
      updateInfo.providerId = provider.id;
      updateInfo.fixedFee = fixedFee;
      updateInfo.customAnnualLimit = customAnnualLimit;
      updateInfo.customMonthlyLimit = customMonthlyLimit;
      updateInfo.customDailyLimit = customDailyLimit;
      updateInfo.quotationClient = quotationClient;
      updateInfo.quotationProvider = quotationProvider;
      updateInfo.providerFee = providerFee;
      updateInfo.active = active;
      updateInfo.feeLowerOption = feeLowerCheck;
    }

    if (feeLowerCheck) {
      updateInfo.fixedFeeLower = fixedFeeLower;
      updateInfo.providerFeeLower = providerFeeLower;
      updateInfo.providerFeeLowerTreshold = providerFeeLowerTreshold;
    } else {
      updateInfo.fixedFeeLower = null;
      updateInfo.providerFeeLower = null;
      updateInfo.providerFeeLowerTreshold = null;
    }

    if (!store || !provider) {
      setAlertMessage("Escolha uma Provedora e uma Loja.");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    if (
      storeProviders.find(
        (storeProvider) =>
          storeProvider.providerId === provider.id &&
          storeProvider.storeId === store.id
      )
    ) {
      setAlertMessage("Essa provedora já está vinculada a essa loja.");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        NEW_STORE_PROVIDER_URL,
        updateInfo,
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 201) {
        setAlertMessage(
          `Provedora: "${
            providers.filter(
              (provider) => provider.id === response.data.providerId
            )[0].name
          }" vinculada a Loja: "${
            stores.filter((store) => store.id === response.data.storeId)[0].name
          }".`
        );
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Provedora");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header
        title="NOVA PROVEDORA"
        subtitle="Vincule suas lojas a uma nova provedora"
        button={false}
        color="#9F5204"
      />
      <Box
        borderRadius="10px"
        padding="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(8, 1fr)"
      >
        <Box sx={{ gridColumn: "span 8" }} />
        <FormControl sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}>
          <Autocomplete
            value={store}
            disableClearable
            variant="outlined"
            id="store"
            options={stores}
            getOptionLabel={(option) => option.name}
            //sx={{ gridColumn: "span 1" }}
            onChange={(_, newValue) => setStore(newValue)}
            renderInput={(params) => <TextField {...params} label="Loja" />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </FormControl>
        <FormControl sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}>
          <Autocomplete
            value={provider}
            disableClearable
            variant="outlined"
            id="store"
            options={providers}
            getOptionLabel={(option) => option.name}
            //sx={{ gridColumn: "span 1" }}
            onChange={(_, newValue) => handleChangeProvider(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Provedora" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </FormControl>
        <NumericFormat
          decimalScale={2}
          thousandSeparator
          fixedDecimalScale
          value={quotationClient}
          customInput={TextField}
          required
          id="quotation-client"
          variant="outlined"
          type="text"
          label="Cotação para o Cliente"
          sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
          onChange={(e) => {
            setQuotationClient(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">BRL</InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />

        <NumericFormat
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator
          value={quotationProvider}
          customInput={TextField}
          required
          id="quotation-provider"
          variant="outlined"
          type="text"
          sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
          label="Dólar da Provedora"
          onChange={(e) => {
            setQuotationProvider(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">BRL</InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />
        <NumericFormat
          decimalScale={2}
          thousandSeparator
          fixedDecimalScale
          value={fixedFee}
          customInput={TextField}
          required
          id="fixed-fee"
          variant="outlined"
          type="text"
          label="Taxa Final para o Cliente"
          sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
          onChange={(e) => {
            setFixedFee(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">USD </InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />

        <NumericFormat
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator
          value={providerFee}
          customInput={TextField}
          required
          id="provider-fee"
          variant="outlined"
          type="text"
          sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
          label="Taxa da Provedora"
          onChange={(e) => {
            setProviderFee(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">USD </InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />
        <Box sx={{ gridColumn: "span 8" }}>
          <p>
            Esta provedora oferece taxas diferenciadas para remessas de baixo
            valor?
          </p>
        </Box>
        <FormControlLabel
          sx={{ gridColumn: "span 2" }}
          control={
            <Checkbox
              value="remember"
              color="primary"
              onChange={handleChangeFeeLowerCheck}
              checked={feeLowerCheck}
            />
          }
          label="Taxa Diferenciada"
        />

        <NumericFormat
          disabled={!feeLowerCheck}
          decimalScale={2}
          thousandSeparator
          fixedDecimalScale
          value={providerFeeLowerTreshold}
          customInput={TextField}
          id="treshold"
          variant="outlined"
          type="text"
          label="Valor Limite da Remessa"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          onChange={(e) => {
            setProviderFeeLowerTreshold(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">USD</InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />
        <NumericFormat
          disabled={!feeLowerCheck}
          decimalScale={2}
          thousandSeparator
          fixedDecimalScale
          value={fixedFeeLower}
          customInput={TextField}
          id="treshold"
          variant="outlined"
          type="text"
          label="Taxa Diferenciada para o Cliente"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          onChange={(e) => {
            setLsetFixedFeeLower(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">USD</InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />
        <NumericFormat
          disabled={!feeLowerCheck}
          decimalScale={2}
          thousandSeparator
          fixedDecimalScale
          value={providerFeeLower}
          customInput={TextField}
          id="treshold"
          variant="outlined"
          type="text"
          label="Taxa Diferenciada da Provedora"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          onChange={(e) => {
            setProviderFeeLower(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">USD</InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />
      </Box>
      {provider && (
        <Box
          borderRadius="10px"
          marginTop="20px"
          padding="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="15px"
          gridTemplateColumns="repeat(8, 1fr)"
        >
          <Box sx={{ gridColumn: "span 8" }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            >
              Limites Personalizados
            </Typography>
            <Typography
              variant="h7"
              sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            >
              Você pode selecionar limites diferentes daqueles estipulados pela
              provedora.
            </Typography>
          </Box>
          <NumericFormat
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            value={customDailyLimit}
            customInput={TextField}
            required
            id="custom-daily-limit"
            variant="outlined"
            type="text"
            label="Limite Diário Personalizado"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            onChange={(e) => {
              setCustomDailyLimit(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            disabled
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            value={provider.dailyLimit}
            customInput={TextField}
            id="daily-limit"
            variant="outlined"
            type="text"
            label="Limite Diário da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            value={customMonthlyLimit}
            customInput={TextField}
            required
            id="custom-monthly-limit"
            variant="outlined"
            type="text"
            label="Limite Mensal Personalizado"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            onChange={(e) => {
              setCustomMonthlyLimit(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            disabled
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            value={provider.monthlyLimit}
            customInput={TextField}
            id="monthly-limit"
            variant="outlined"
            type="text"
            label="Limite Mensal da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <NumericFormat
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            value={customAnnualLimit}
            customInput={TextField}
            required
            id="custom-annual-limit"
            variant="outlined"
            type="text"
            label="Limite Anual Personalizado"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            onChange={(e) => {
              setCustomAnnualLimit(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            disabled
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            value={provider.annualLimit}
            customInput={TextField}
            id="annual-limit"
            variant="outlined"
            type="text"
            label="Limite Anual da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <FormControlLabel
            sx={{ gridColumn: "span 4" }}
            control={
              <Checkbox
                value="remember"
                color="primary"
                onChange={handleChangeActive}
                checked={active}
              />
            }
            label="Provedora Ativa"
          />
        </Box>
      )}
      {provider && (
        <Box
          borderRadius="10px"
          marginTop="20px"
          padding="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="15px"
          gridTemplateColumns="repeat(8, 1fr)"
        >
          <Box
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            visibility={false}
          />
          <Button
            type="submit"
            disabled={!provider || !store}
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Typography fontWeight="bold">Vincular Provedora</Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            {" "}
            <Typography fontWeight="bold">Cancelar</Typography>
          </ColorButton>
        </Box>
      )}
    </Box>
  );
};
export default NewStoreProvider;
