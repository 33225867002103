import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Clients from "..";
import Recipients from "../../Recipients";
import Header from "../../../components/Header";

export default function ClientsTab() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="20px" marginLeft="300px">
      <Header
        title="Clientes e Beneficiários"
        subtitle="Gerencie a lista de Clientes e seus Beneficiários"
        color={value === "1" ? "#004549" : "#E35A01"}
      />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            scrollButtons="auto"
            allowScrollButtonsMobile
            indicatorColor="secondary"
            textColor="inherit"
            onChange={handleChange}
            aria-label="Alterne entre clientes e beneficiários"
          >
            <Tab label="Clientes" value="1" />
            <Tab label="Beneficiários" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">{<Clients />}</TabPanel>
        <TabPanel value="2">{<Recipients />}</TabPanel>
      </TabContext>
    </Box>
  );
}
