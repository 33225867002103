import {
  Box,
  Button,
  LinearProgress,
  useMediaQuery,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CustomNoRowsOverlay from "../../../components/NoRowsOverlay";
import useAxiosPrivate from "../../../api/useAxiosPrivate";

import { tokens } from "../../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useTheme } from "@mui/material";
import StoreDialog from "../../../components/StoresDialog";
import EditStoreDialog from "../../../components/EditStoreDialog";
import DeleteStoreDialog from "../../../components/DeleteStoreDialog";
import { useState, useEffect } from "react";
import Toast from "../../../components/Snackbar";
import { styled } from "@mui/material/styles";

const Stores = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [stores, setStores] = useState([]);
  const [store_info, setStoreInfo] = useState({});

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [loadingData, setLoadingData] = useState(true);
  const [openNewStoreDialog, setOpenNewStoreDialog] = useState(false);
  const [openEditStoreDialog, setOpenEditStoreDialog] = useState(false);
  const [openDeleteStoreDialog, setOpenDeleteStoreDialog] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await axiosPrivate.get("/stores");
        setStores(response.data);
        setLoadingData(false);
      } catch (err) {}
    };

    getStores();
  }, [openNewStoreDialog, openEditStoreDialog, openDeleteStoreDialog]);

  const newStore = () => {
    setOpenNewStoreDialog(true);
  };

  const handleClose = () => {
    setOpenNewStoreDialog(false);
    setOpenEditStoreDialog(false);
    setOpenDeleteStoreDialog(false);
  };

  const handleEditClick = (id) => {
    const info = stores.filter((store) => store.id === id);
    setStoreInfo(info[0]);
    setOpenEditStoreDialog(true);
  };

  const handleDeleteClick = (id) => () => {
    const info = stores.filter((store) => store.id === id);
    setStoreInfo(info[0]);
    setOpenDeleteStoreDialog(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Loja",
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "phoneNumber",
      headerName: "Telefone",
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Opções",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={(e) => handleEditClick(id)}
            color="inherit"
          />,

          /*<GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        */

          ,
        ];
      },
    },
  ];

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#621B74",
    "&:hover": {
      backgroundColor: "#814990",
    },
  }));

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 1,
          display: "flex",
          gap: "10px",
        }}
      >
        <GridToolbarQuickFilter
          placeholder="Busque pelo Nome da Loja"
          variant="outlined"
          style={{ flex: 0.5 }}
        />
        <ColorButton
          variant="contained"
          onClick={newStore}
          sx={{ gridColumn: "span 2" }}
        >
          <AddCircleOutlinedIcon sx={{ mr: "10px" }} />
          <Typography fontWeight="bold">NOVO</Typography>
        </ColorButton>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: "#0c1c3c",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#621B74",
            borderBottom: "none",
            color: "white",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#fff",
          },
        }}
      >
        <DataGrid
          loading={loadingData}
          getRowId={(row) => row.id}
          rows={stores}
          columns={columns}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{
            toolbar: QuickSearchToolbar,
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
      <Toast
        open={openAlert}
        alertMessage={alertMessage}
        setOpen={setOpenAlert}
        severity={severity}
      />
      <StoreDialog
        openNewStoreDialog={openNewStoreDialog}
        handleClose={handleClose}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />

      <EditStoreDialog
        openEditStoreDialog={openEditStoreDialog}
        handleClose={handleClose}
        store_info={store_info}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <DeleteStoreDialog
        openDeleteStoreDialog={openDeleteStoreDialog}
        handleClose={handleClose}
        store_info={store_info}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
    </Box>
  );
};

export default Stores;
