import {
  Box,
  Button,
  TextField,
  useTheme,
  Autocomplete,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { styled } from "@mui/material/styles";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
const today = dayjs();

const DeleteTransaction = (props) => {
  const { handleClose } = props;

  const DELETE_TRANSACTION_URL = "/transactions/";
  const axiosPrivate = useAxiosPrivate();

  const {
    stores,
    clients,
    storeProviders,
    recipients,
    transactionInfo,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  const countries = [{ id: 1, name: "Brasil" }];

  const paymentMethods = [
    { id: 1, name: "Cash" },
    { id: 2, name: "Link Remoto" },
    { id: 3, name: "Cartão de Débito" },
    { id: 4, name: "Outro" },
  ];

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [store, setStore] = useState(
    stores.filter((store) => store.id === transactionInfo.storeId)[0]
  );
  const [client, setClient] = useState(
    clients.filter((client) => client.id === transactionInfo.clientId)[0]
  );
  const [recipient, setRecipient] = useState(
    recipients.filter(
      (recipient) => recipient.id === transactionInfo.recipientId
    )[0]
  );
  const [amount, setAmount] = useState(transactionInfo.amount);
  const [paymentMethod, setPaymentMethod] = useState(
    paymentMethods.filter(
      (payment) => payment.name === transactionInfo.paymentMethod
    )[0]
  );
  const [fee, setFee] = useState(transactionInfo.fee);
  const [profit, setProfit] = useState(transactionInfo.profit);
  const [provider, setProvider] = useState(
    storeProviders.filter(
      (storeProvider) => storeProvider.id === transactionInfo.providerId
    )[0]
  );
  const [quotationProvider, setQuotationProvider] = useState(
    transactionInfo.quotationProvider
  );
  const [quotationClient, setQuotationClient] = useState(
    transactionInfo.quotationClient
  );
  const [spread, setSpread] = useState(transactionInfo.spread);
  const [dateTransaction, setDateTransaction] = useState(
    dayjs(transactionInfo.dateTransaction)
  );
  const [destinyCountry, setDestinyCountry] = useState(
    countries.filter(
      (country) => country.name === transactionInfo.destinyCountry
    )[0]
  );

  const [clientRecipients, setClientRecipients] = useState(
    recipients.filter((recipient) => {
      return recipient.client.id === transactionInfo.clientId;
    })
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if button enabled with JS hack

    try {
      const response = await axiosPrivate.delete(
        `${DELETE_TRANSACTION_URL}${transactionInfo.id}`,
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );
      if (response.status === 204) {
        setAlertMessage(`Remessa removida com sucesso!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Remessa");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header
        title="!REMOVER REMESSA"
        subtitle="Remova a Remessa do Histórico"
        button={false}
        color="#9f1853"
      />

      <Box
        sx={{
          borderRadius: 3,
          autoComplete: "off",
          padding: 3,
          gap: 2,
          bgcolor: colors.primary[400],
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Loja
        </Typography>
        <TextField
          disabled
          size="small"
          label="Loja"
          fullWidth
          value={store.name}
          disableClearable
          variant="outlined"
          id="country"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
        />
        <Autocomplete
          fullWidth
          required
          value={destinyCountry}
          disableClearable
          variant="outlined"
          id="country"
          options={countries}
          getOptionLabel={(option) => option.name}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          onChange={(_, newValue) => setDestinyCountry(newValue)}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Pais de Destino" />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <Box sx={{ gridColumn: "span 2" }}></Box>
      </Box>
      <Box
        borderRadius="10px"
        marginTop="20px"
        padding="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          Cliente
        </Typography>

        <Box sx={{ gridColumn: "span 4" }} />

        <Autocomplete
          disabled
          fullWidth
          size="small"
          value={client}
          disableClearable
          variant="outlined"
          id="nome-cliente"
          options={clients}
          getOptionLabel={(option) => `${option.name}`}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          renderInput={(params) => (
            <TextField {...params} label="Nome do Cliente" />
          )}
        />
        {client && (
          <>
            <TextField
              size="small"
              fullWidth
              value={client.phoneNumber}
              variant="outlined"
              type="text"
              label="Telefone"
              //name="Telefone"
              sx={{
                backgroundColor: colors.grey[900],
                gridColumn: isNonMobile ? "span 1" : "span 2",
              }}
              inputProps={{ readOnly: true }}
            />
            <TextField
              size="small"
              fullWidth
              value={client.city}
              variant="outlined"
              type="text"
              label="Cidade"
              sx={{
                backgroundColor: colors.grey[900],
                gridColumn: isNonMobile ? "span 1" : "span 2",
              }}
              inputProps={{ readOnly: true }}
            />
          </>
        )}
      </Box>

      {/* PARTE DO BENEFICIÁRO */}
      {client && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Beneficiário
          </Typography>
          <Box sx={{ gridColumn: "span 4" }} visibility="hidden" />
          <TextField
            disabled
            value={recipient.name}
            variant="outlined"
            size="small"
            label="Nome do Beneficiário"
            id="nome-beneficiario"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 2" }}
          />
          <TextField
            disabled
            value={recipient.identificationDocument}
            variant="outlined"
            size="small"
            label="Documento de Identificação"
            id="nome-beneficiario"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          />
        </Box>
      )}

      {/* PARTE DA REMESSA */}

      {recipient && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }}>
            <Typography variant="h4" fontWeight="bold">
              Remessa
            </Typography>
          </Box>

          <DatePicker
            disabled
            disableFuture
            label="Data da Remessa (YYYY-MM-DD)"
            value={dateTransaction}
            defaultValue={today}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            format="YYYY-MM-DD"
          />
          <NumericFormat
            disabled
            decimalScale={2}
            fixedDecimalScale
            value={amount}
            customInput={TextField}
            required
            id="amount"
            variant="outlined"
            type="text"
            label="Valor da Remessa com Taxas"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <Autocomplete
            disabled
            fullWidth
            value={paymentMethod}
            disableClearable
            variant="outlined"
            id="payment-method"
            options={paymentMethods}
            getOptionLabel={(option) => option.name}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            renderInput={(params) => (
              <TextField {...params} label="Forma de Pagamento" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />

          <Autocomplete
            disabled
            fullWidth
            value={provider}
            disableClearable
            variant="outlined"
            id="provider"
            options={storeProviders.filter((provider) => provider.active)}
            getOptionLabel={(option) => option.Provider.name}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
            renderInput={(params) => (
              <TextField {...params} label="Provedora" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <NumericFormat
            disabled
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator=","
            value={fee}
            customInput={TextField}
            required
            id="fee"
            variant="outlined"
            type="text"
            label="Taxa"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <NumericFormat
            disabled
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator=","
            value={quotationProvider}
            customInput={TextField}
            id="quotation-provider"
            variant="outlined"
            type="text"
            label="Real da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">BRL </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <Box
            display="grid"
            rowGap={isNonMobile ? "0px" : "15px"}
            columnGap="15px"
            gridTemplateColumns="repeat(3, 1)"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            border="0px"
          >
            <Box sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }} />
            <NumericFormat
              disabled
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator=","
              value={quotationClient}
              customInput={TextField}
              id="quotation-client"
              variant="outlined"
              type="text"
              label="Real do Cliente"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginLeft: isNonMobile ? "20px" : "Opx",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">BRL </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
            {isNonMobile && (
              <Typography
                align="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 1" }}
              >
                ou{" "}
              </Typography>
            )}
            <NumericFormat
              disabled
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              value={spread}
              customInput={TextField}
              required
              id="spread"
              variant="outlined"
              type="text"
              label="Spread"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginRight: isNonMobile ? "20px" : "0px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">BRL </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
          </Box>
          <NumericFormat
            disabled
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator=","
            value={profit}
            customInput={TextField}
            required
            id="profit"
            variant="outlined"
            type="text"
            label="Lucro"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          {transactionInfo.receiptURL && (
            <Button
              startIcon={<CloudDownloadOutlinedIcon />}
              href={transactionInfo.receiptURL}
              variant="contained"
              sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
            >
              BAIXAR RECIBO
            </Button>
          )}
        </Box>
      )}
      <Box height="20px">
        <Box
          borderRadius="10px"
          padding="10px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="5px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }} />
          <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            <Typography fontWeight="bold">Remover Remessa</Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            Cancelar
          </ColorButton>
        </Box>
      </Box>
      <Box />
    </Box>
  );
};

export default DeleteTransaction;
