import {
  Box,
  Button,
  TextField,
  useTheme,
  Autocomplete,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { styled } from "@mui/material/styles";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
const today = dayjs();

const EditTransaction = (props) => {
  const { handleClose } = props;

  const EDIT_TRANSACTION_URL = "/transactions/";
  const axiosPrivate = useAxiosPrivate();

  const {
    stores,
    clients,
    storeProviders,
    recipients,
    transactionInfo,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  const countries = [{ id: 1, name: "Brasil" }];

  const paymentMethods = [
    { id: 1, name: "Cash" },
    { id: 2, name: "Link Remoto" },
    { id: 3, name: "Cartão de Débito" },
    { id: 4, name: "Outro" },
  ];

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const [store, setStore] = useState(
    stores.filter((store) => store.id === transactionInfo.storeId)[0]
  );
  const [client, setClient] = useState(
    clients.filter((client) => client.id === transactionInfo.clientId)[0]
  );
  const [recipient, setRecipient] = useState(
    recipients.filter(
      (recipient) => recipient.id === transactionInfo.recipientId
    )[0]
  );
  const [amount, setAmount] = useState(transactionInfo.amount);
  const [paymentMethod, setPaymentMethod] = useState(
    paymentMethods.filter(
      (payment) => payment.name === transactionInfo.paymentMethod
    )[0]
  );
  const [fee, setFee] = useState(transactionInfo.fee);
  const [profit, setProfit] = useState(transactionInfo.profit);
  const [provider, setProvider] = useState(
    storeProviders.filter(
      (storeProvider) => storeProvider.id === transactionInfo.storeProviderId
    )[0]
  );
  const [quotationProvider, setQuotationProvider] = useState(
    transactionInfo.quotationProvider
  );
  const [quotationClient, setQuotationClient] = useState(
    transactionInfo.quotationClient
  );
  const [spread, setSpread] = useState(transactionInfo.spread);
  const [dateTransaction, setDateTransaction] = useState(
    dayjs(transactionInfo.dateTransaction)
  );
  const [destinyCountry, setDestinyCountry] = useState(
    countries.filter(
      (country) => country.name === transactionInfo.destinyCountry
    )[0]
  );

  const [filteredStoreProviders, setFilteredStoreProviders] = useState(
    storeProviders.filter((provider) => provider.StoreId === store.id)
  );

  const [recipientBank, setRecipientBank] = useState([]);

  const formData = new FormData();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if button enabled with JS hack

    if (!store || !amount || !provider || !dateTransaction || !profit) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    formData.append("storeId", store.id);
    formData.append("destinyCountry", destinyCountry.name);
    formData.append("storeProviderId", provider.id);
    formData.append("amount", amount);
    formData.append("paymentMethod", paymentMethod.name);
    formData.append("fee", fee);
    formData.append("profit", profit);
    formData.append("quotationProvider", quotationProvider);
    formData.append("quotationClient", quotationClient);
    formData.append("dateTransaction", dateTransaction);

    if (selectedFile) {
      formData.append("receipt", selectedFile);
    }

    try {
      const response = await axiosPrivate.putForm(
        `${EDIT_TRANSACTION_URL}${transactionInfo.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );
      if (response.status === 204) {
        setAlertMessage(`Remessa alterada!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Remessa");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  const handleStoreSelect = (store) => {
    setStore(store);
    setFilteredStoreProviders(
      storeProviders.filter((provider) => provider.storeId === store.id)
    );
    setProvider(null);
  };

  const handleProfitSelect = (value) => {
    setProfit(value);
  };

  const handleQuotationProvider = (value) => {
    setQuotationProvider(value);
    if ((quotationClient > 0) & (value > 0)) {
      setSpread(value - quotationClient);
      setProfit(
        (spread * (amount - fee)) / quotationProvider +
          (fee - provider.providerFee)
      );
    } else {
      setSpread(0);
      setProfit(0);
    }
  };

  const handleQuotationClient = (value) => {
    setQuotationClient(value);
    if ((quotationProvider > 0) & (value > 0)) {
      setSpread(quotationProvider - value);
      setProfit(
        ((quotationProvider - value) * (amount - fee)) / quotationProvider +
          (fee - provider.providerFee)
      );
    } else {
      setSpread(0);
      setProfit(0);
    }
  };

  const handleSpreadSelect = (value) => {
    setSpread(value);
    if (
      quotationProvider > 0 &&
      (!quotationClient || quotationClient == 0) &&
      (!profit || profit == 0)
    ) {
      setQuotationClient(quotationProvider - value);
      setProfit(
        (value * (amount - fee)) / quotationProvider +
          (fee - provider.providerFee)
      );
    }
  };

  const handleProviderSelect = (provider) => {
    setProvider(provider);
    setFee(provider.fixedFee);
  };

  const handleAmountSelect = (newValue) => {
    setAmount(newValue);
    if (
      quotationProvider > 0 &&
      quotationClient > 0 &&
      provider &&
      newValue > 0
    ) {
      setProfit(
        ((quotationProvider - quotationClient) * (newValue - fee)) /
          quotationProvider +
          (fee - provider.providerFee)
      );
    } else {
      setProfit(0);
    }
  };

  return (
    <Box>
      <Header
        title="EDITAR REMESSA"
        subtitle="Edite as informações da Remessa"
        button={false}
        color="#9f1853"
      />

      <Box
        sx={{
          borderRadius: 3,
          autoComplete: "off",
          padding: 3,
          gap: 2,
          bgcolor: colors.primary[400],
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Loja
        </Typography>
        <Autocomplete
          fullWidth
          required
          value={store}
          disableClearable
          variant="outlined"
          id="country"
          options={stores}
          getOptionLabel={(option) => option.name}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          onChange={(_, newValue) => handleStoreSelect(newValue)}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Loja" />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <Autocomplete
          fullWidth
          required
          value={destinyCountry}
          disableClearable
          variant="outlined"
          id="country"
          options={countries}
          getOptionLabel={(option) => option.name}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          onChange={(_, newValue) => setDestinyCountry(newValue)}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Pais de Destino" />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <Box sx={{ gridColumn: "span 2" }}></Box>
      </Box>
      <Box
        borderRadius="10px"
        marginTop="20px"
        padding="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          Cliente
        </Typography>

        <Box sx={{ gridColumn: "span 4" }} />

        <Autocomplete
          disabled
          fullWidth
          size="small"
          value={client}
          disableClearable
          variant="outlined"
          id="nome-cliente"
          options={clients}
          getOptionLabel={(option) => `${option.name}`}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          renderInput={(params) => (
            <TextField {...params} label="Nome do Cliente" />
          )}
        />
        {client && (
          <>
            <TextField
              size="small"
              fullWidth
              value={client.phoneNumber}
              variant="outlined"
              type="text"
              label="Telefone"
              //name="Telefone"
              sx={{
                backgroundColor: colors.grey[900],
                gridColumn: isNonMobile ? "span 1" : "span 2",
              }}
              inputProps={{ readOnly: true }}
            />
            <TextField
              size="small"
              fullWidth
              value={client.city}
              variant="outlined"
              type="text"
              label="Cidade"
              sx={{
                backgroundColor: colors.grey[900],
                gridColumn: isNonMobile ? "span 1" : "span 2",
              }}
              inputProps={{ readOnly: true }}
            />
          </>
        )}
      </Box>

      {/* PARTE DO BENEFICIÁRO */}
      {client && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Beneficiário
          </Typography>
          <Box sx={{ gridColumn: "span 4" }} visibility="hidden" />
          <TextField
            disabled
            value={recipient.name}
            variant="outlined"
            size="small"
            label="Nome do Beneficiário"
            id="nome-beneficiario"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 2" }}
          />
          <TextField
            disabled
            value={recipient.identificationDocument}
            variant="outlined"
            size="small"
            label="Documento de Identificação"
            id="nome-beneficiario"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          />
        </Box>
      )}

      {/* PARTE DA REMESSA */}

      {recipient && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }}>
            <Typography variant="h4" fontWeight="bold">
              Remessa
            </Typography>
          </Box>

          <DatePicker
            disableFuture
            label="Data da Remessa (AAAA-MM-DD)"
            value={dateTransaction}
            defaultValue={today}
            onChange={(newValue) => setDateTransaction(newValue)}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            format="YYYY-MM-DD"
          />
          <NumericFormat
            decimalScale={2}
            fixedDecimalScale
            value={amount}
            customInput={TextField}
            required
            id="amount"
            variant="outlined"
            type="text"
            label="Valor da Remessa com Taxas"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            onValueChange={(event) => handleAmountSelect(event.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <Autocomplete
            fullWidth
            value={paymentMethod}
            disableClearable
            variant="outlined"
            id="payment-method"
            options={paymentMethods}
            getOptionLabel={(option) => option.name}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            onChange={(_, newValue) => setPaymentMethod(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Forma de Pagamento" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />

          <Autocomplete
            fullWidth
            value={provider}
            disableClearable
            variant="outlined"
            id="provider"
            options={filteredStoreProviders.filter(
              (provider) => provider.active
            )}
            getOptionLabel={(option) => option.Provider.name}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
            onChange={(_, newValue) => handleProviderSelect(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Provedora" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <NumericFormat
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator=","
            value={fee}
            customInput={TextField}
            required
            id="fee"
            variant="outlined"
            type="text"
            label="Taxa"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
            onChange={(event) => setFee(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <NumericFormat
            disabled={amount <= 0 || !fee}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator=","
            value={quotationProvider}
            customInput={TextField}
            id="quotation-provider"
            variant="outlined"
            type="text"
            label="Cotação da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            onChange={(event) => handleQuotationProvider(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">BRL </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <Box
            display="grid"
            rowGap={isNonMobile ? "0px" : "15px"}
            columnGap="15px"
            gridTemplateColumns="repeat(3, 1)"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            border="0px"
          >
            <Box sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }} />
            <NumericFormat
              disabled={
                amount <= 0 ||
                !fee ||
                !quotationProvider ||
                quotationProvider == 0
              }
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator=","
              value={quotationClient}
              customInput={TextField}
              id="quotation-client"
              variant="outlined"
              type="text"
              label="Cotação do Cliente"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginLeft: isNonMobile ? "20px" : "Opx",
              }}
              onChange={(event) => handleQuotationClient(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">BRL </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
            {isNonMobile && (
              <Typography
                align="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 1" }}
              >
                ou{" "}
              </Typography>
            )}
            <NumericFormat
              disabled={quotationClient > 0}
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator=","
              value={spread}
              customInput={TextField}
              required
              id="spread"
              variant="outlined"
              type="text"
              label="Spread"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginRight: isNonMobile ? "20px" : "0px",
              }}
              //onChange={(event) => setSpread(event.target.value)}
              onBlur={(event) => handleSpreadSelect(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">BRL </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
          </Box>
          <NumericFormat
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator=","
            value={profit}
            customInput={TextField}
            required
            id="profit"
            variant="outlined"
            type="text"
            label="Lucro"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            onChange={(event) => handleProfitSelect(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          {transactionInfo.receiptURL && (
            <>
              <Button
                startIcon={<CloudDownloadOutlinedIcon />}
                href={transactionInfo.receiptURL}
                variant="contained"
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
              >
                DOWNLOAD RECIBO
              </Button>

              <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadOutlinedIcon />}
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
              >
                SUBSTITUIR RECIBO
                <input type="file" hidden onChange={handleFileChange} />
              </Button>
              {isNonMobile && <Box sx={{ gridColumn: "span 2" }} />}
              {selectedFile && (
                <Typography
                  sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
                >
                  Arquivo: <b>{selectedFile.name}</b>
                </Typography>
              )}
            </>
          )}
          {!transactionInfo.receiptURL && (
            <>
              <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadOutlinedIcon />}
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
              >
                UPLOAD RECIBO
                <input type="file" hidden onChange={handleFileChange} />
              </Button>
              <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 2" }} />
              {selectedFile && (
                <Typography
                  sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
                >
                  Arquivo: <b>{selectedFile.name}</b>
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
      <Box height="20px">
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(8, 1fr)",
          }}
        >
          {isNonMobile && <Box sx={{ gridColumn: "span 4" }} />}
          <Button
            type="submit"
            disabled={
              !store ||
              !client ||
              !recipient ||
              !amount ||
              !provider ||
              !dateTransaction ||
              !profit
            }
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Typography fontWeight="bold">Atualizar Remessa</Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            Cancelar
          </ColorButton>
        </Box>
      </Box>
      <Box />
    </Box>
  );
};

export default EditTransaction;
