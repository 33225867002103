import { useState, useEffect } from "react";
import { Box, TextField, useTheme, Button, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAxiosPrivate from "../../../api/useAxiosPrivate";

const GeneralSettings = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  const [subsciptionInfo, setSubscriptionInfo] = useState({});

  useEffect(() => {
    const getSubscriptionInfo = async () => {
      try {
        const response = await axiosPrivate.get("/subscriptions/storeChain");
        setSubscriptionInfo(response.data);
      } catch (err) {}
    };

    getSubscriptionInfo();
  }, []);

  const stripeCustomerPortal = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.post("/stripeCustomerPortal");
      console.log(response.data);
      window.open(response.data.url);
    } catch (error) {}
  };

  return (
    <Box>
      <Box width={"100%"}>
        <Box
          borderRadius="10px"
          autoComplete="off"
          padding="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="10px"
          gridTemplateColumns="repeat(4, 1fr)"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}
        >
          <TextField
            id="subscriptionStart"
            fullWidth
            label="Início da Assinatura"
            variant="outlined"
            inputProps={{ readOnly: true }}
            value={subsciptionInfo.subscriptionStart || ""}
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            id="nextBill"
            fullWidth
            label="Próxima Fatura"
            variant="outlined"
            inputProps={{ readOnly: true }}
            value={subsciptionInfo.currentPeriodEnd || ""}
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            id="status"
            fullWidth
            label="Status da Assinatura"
            variant="outlined"
            inputProps={{ readOnly: true }}
            value={subsciptionInfo.active || ""}
            sx={{ gridColumn: "span 2" }}
          />
          <Box sx={{ gridColumn: "span 2" }} />
          <Box sx={{ gridColumn: "span 2" }} />

          <Button
            color="secondary"
            variant="contained"
            onClick={stripeCustomerPortal}
            sx={{ gridColumn: "span 2" }}
          >
            <Typography fontWeight="bold">Gerenciar Plano</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
