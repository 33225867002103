import { useState, useEffect } from "react";

import {
  Box,
  TextField,
  useTheme,
  Typography,
  useMediaQuery,
  Paper,
  Button,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

const today = dayjs();

const ClientMiniReport = (props) => {
  const { handleClose, clientInfo } = props;

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  const name = clientInfo.name;
  const phoneNumber = clientInfo.phoneNumber;
  const birthDate = clientInfo.birthDate ? dayjs(clientInfo.birthDate) : null;
  const email = clientInfo.email;
  const address = clientInfo.address;
  const city = clientInfo.city;
  const state = clientInfo.state;
  const zipCode = clientInfo.zipCode;
  const country = clientInfo.country;
  const documentType = clientInfo.documentType;
  const documentExpiration = clientInfo.documentExpiration
    ? dayjs(clientInfo.documentExpiration)
    : null;
  const passportNumber = clientInfo.passportNumber;
  const passportExpiration = clientInfo.passportExpiration
    ? dayjs(clientInfo.passportExpiration)
    : null;
  const driverLicense = clientInfo.driverLicense;
  const driverLicenseExpiration = clientInfo.driverLicenseExpiration
    ? dayjs(clientInfo.driverLicenseExpiration)
    : null;
  const proofFundsExpiration = clientInfo.proofFundsExpiration
    ? dayjs(clientInfo.proofFundsExpiration)
    : null;

  const socialSecurityNumber = clientInfo.socialSecurityNumber;
  const individualTaxpayerIdentificationNumber =
    clientInfo.individualTaxpayerIdentificationNumber;

  const [clientTransactionsTotal, setClientTransactionsTotal] = useState([]);
  const [clientTransactionsByProvider, setClientTransactionsByProvider] =
    useState([]);

  const [transactions, setTransactions] = useState([]);
  const [recipients, setRecipients] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getRecipients = async () => {
      try {
        const response = await axiosPrivate.get(
          `/recipients/clients/${clientInfo.id}`
        );
        setRecipients(response.data);
      } catch (err) {}
    };
    getRecipients();
  }, []);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const response = await axiosPrivate.get(
          `/transactions/client/${clientInfo.id}`
        );
        setClientTransactionsByProvider(transactionsByProvider(response.data));
        setClientTransactionsTotal(transactionsTotal(response.data));
      } catch (err) {}
    };
    getTransactions();
  }, []);

  const transactionsByProvider = (transactions) => {
    var result = [];
    transactions.reduce(function (res, value) {
      if (!res[value.storeProvider.Provider.name]) {
        res[value.storeProvider.Provider.name] = {
          providerName: value.storeProvider.Provider.name,
          total: 0,
          last7Days: 0,
          last30Days: 0,
          lastYear: 0,
        };
        result.push(res[value.storeProvider.Provider.name]);
      }
      res[value.storeProvider.Provider.name].total += value.amount;
      if (dayjs(value.dateTransaction) >= today.subtract(7, "days")) {
        res[value.storeProvider.Provider.name].last7Days += value.amount;
      }
      if (dayjs(value.dateTransaction) >= today.subtract(30, "days")) {
        res[value.storeProvider.Provider.name].last30Days += value.amount;
      }
      if (dayjs(value.dateTransaction) >= today.subtract(365, "days")) {
        res[value.storeProvider.Provider.name].lastYear += value.amount;
      }
      return res;
    }, {});
    return result;
  };

  const transactionsTotal = (transactions) => {
    var result = [];
    transactions.reduce(function (res, value) {
      if (!res["total"]) {
        res["total"] = {
          name: "Total",
          total: 0,
          last7Days: 0,
          last30Days: 0,
          lastYear: 0,
        };
        result.push(res["total"]);
      }
      res["total"].total += value.amount;
      if (dayjs(value.dateTransaction) >= today.subtract(7, "days")) {
        res["total"].last7Days += value.amount;
      }
      if (dayjs(value.dateTransaction) >= today.subtract(30, "days")) {
        res["total"].last30Days += value.amount;
      }
      if (dayjs(value.dateTransaction) >= today.subtract(365, "days")) {
        res["total"].lastYear += value.amount;
      }
      return res;
    }, {});
    return result;
  };

  return (
    <Box>
      <Box>
        <Header
          title={`${clientInfo.name}`}
          subtitle="Informações do Cliente"
          button={false}
          color="#004549"
        />

        <Box
          sx={{
            borderRadius: 3,
            autoComplete: "off",
            padding: 3,
            gap: 2,
            bgcolor: colors.primary[400],
            display: "grid",
            gridTemplateColumns: "repeat(8, 1fr)",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
          >
            Informações Cadastrais
          </Typography>
          <Box sx={{ gridColumn: isNonMobile ? "span 8" : "span 8" }} />

          <TextField
            size="small"
            id="name"
            label="Nome"
            variant="outlined"
            value={name}
            sx={{ gridColumn: isNonMobile ? "span 5" : "span 8" }}
            inputProps={{ readOnly: true }}
          />
          <TextField
            size="small"
            id="phoneNumber"
            label="Telefone"
            variant="outlined"
            value={[phoneNumber]}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 8" }}
            inputProps={{ readOnly: true }}
          />
          <TextField
            size="small"
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            sx={{ gridColumn: isNonMobile ? "span 5" : "span 8" }}
            inputProps={{ readOnly: true }}
          />
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            id="birthDate"
            variant="outlined"
            disableFuture
            label="Data de Nascimento"
            defaultValue={today}
            value={birthDate}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 8" }}
            format="YYYY-MM-DD"
            readOnly
          />
          <TextField
            size="small"
            id="address"
            label="Endereço"
            variant="outlined"
            value={address}
            sx={{ gridColumn: isNonMobile ? "span 5" : "span 8" }}
            inputProps={{ readOnly: true }}
          />
          <TextField
            size="small"
            id="zipCode"
            label="ZipCode"
            variant="outlined"
            value={zipCode}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
            inputProps={{ readOnly: true }}
          />
          <TextField
            size="small"
            id="city"
            label="Cidade"
            variant="outlined"
            value={city}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
            inputProps={{ readOnly: true }}
          />
          <TextField
            size="small"
            id="state"
            label="Estado"
            variant="outlined"
            value={state}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            inputProps={{ readOnly: true }}
          />
          <TextField
            size="small"
            id="country"
            label="Pais"
            variant="outlined"
            value={country}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
            inputProps={{ readOnly: true }}
          />

          <TextField
            size="small"
            id="passportNumber"
            label="Número do Passaporte"
            variant="outlined"
            value={passportNumber}
            inputProps={{ readOnly: true }}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
          />
          <DatePicker
            slotProps={{ textField: { size: "small", error: false } }}
            id="passportExpiration"
            disabled={!passportNumber}
            label="Validade do Passaporte"
            variant="outlined"
            value={passportExpiration}
            readOnly
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            format="YYYY-MM-DD"
          />
          <TextField
            size="small"
            id="socialSecurityNumber"
            label="Social Security Number"
            variant="outlined"
            value={socialSecurityNumber}
            inputProps={{ readOnly: true }}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 8" }}
          />
          <TextField
            size="small"
            id="driverLicense"
            label="Número da Habilitação"
            variant="outlined"
            value={driverLicense}
            inputProps={{ readOnly: true }}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}
          />
          <DatePicker
            slotProps={{ textField: { size: "small", error: false } }}
            id="driverLicenseExpiration"
            label="Validade da Habilitação"
            disabled={!driverLicense}
            variant="outlined"
            value={driverLicenseExpiration}
            readOnly
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            format="YYYY-MM-DD"
          />

          <TextField
            size="small"
            id="individualTaxpayerIdentificationNumber"
            label="Individual Taxpayer Identification Number"
            variant="outlined"
            value={individualTaxpayerIdentificationNumber}
            inputProps={{ readOnly: true }}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 8" }}
          />
          {clientInfo.documentURL && (
            <>
              <Divider variant="middle" sx={{ gridColumn: "span 8" }} />
              <Button
                href={clientInfo.documentURL}
                variant="contained"
                startIcon={<CloudDownloadOutlinedIcon />}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 8" }}
              >
                BAIXAR DOCUMENTO
              </Button>
              {isNonMobile && <Box sx={{ gridColumn: "span 2" }} />}
              <TextField
                size="small"
                id="documentType"
                disabled
                label="Tipo do Documento"
                variant="outlined"
                value={documentType}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
              />
              <DatePicker
                id="documentExpiration"
                disabled
                label="Validade do Documento"
                variant="outlined"
                value={documentExpiration}
                format="YYYY-MM-DD"
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
                slotProps={{
                  textField: {
                    error: false,
                    size: "small",
                  },
                }}
              />
            </>
          )}

          {clientInfo.proofFundsURL && (
            <>
              <Divider variant="middle" sx={{ gridColumn: "span 8" }} />

              <Button
                href={clientInfo.proofFundsURL}
                variant="contained"
                startIcon={<CloudDownloadOutlinedIcon />}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 8" }}
              >
                BAIXAR PROVA DE FUNDOS
              </Button>

              {<Box sx={{ gridColumn: "span 4" }} />}

              <DatePicker
                id="proofFundsExpiration"
                label="Validade da Prova de Fundos"
                disabled
                variant="outlined"
                value={proofFundsExpiration}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
                format="YYYY-MM-DD"
                slotProps={{
                  textField: {
                    error: false,
                    size: "small",
                  },
                }}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            borderRadius: 3,
            autoComplete: "off",
            padding: 3,
            gap: 2,
            bgcolor: colors.primary[400],
            display: "grid",
            gridTemplateColumns: "repeat(8, 1fr)",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
          >
            Informações de Remessas
          </Typography>
          <Box sx={{ gridColumn: isNonMobile ? "span 8" : "span 8" }} />

          {clientTransactionsByProvider.length > 0 ? (
            <TableContainer
              component={Paper}
              sx={{ gridColumn: "span 8", maxHeight: 200 }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <b>Provedora</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>7 Dias</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>30 Dias</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>365 Dias</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Total</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientTransactionsByProvider.map((row) => (
                    <TableRow
                      key={row.providerName}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.providerName}</TableCell>
                      <TableCell>{`USD ${USDollar.format(
                        row.last7Days
                      )}`}</TableCell>
                      <TableCell>{`USD ${USDollar.format(
                        row.last30Days
                      )}`}</TableCell>
                      <TableCell>{`USD ${USDollar.format(
                        row.lastYear
                      )}`}</TableCell>
                      <TableCell>{`USD ${USDollar.format(
                        row.total
                      )}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <b>TOTAL</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{`USD ${USDollar.format(
                        clientTransactionsTotal[0].last7Days
                      )}`}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{`USD ${USDollar.format(
                        clientTransactionsTotal[0].last30Days
                      )}`}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{`USD ${USDollar.format(
                        clientTransactionsTotal[0].lastYear
                      )}`}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{`USD ${USDollar.format(
                        clientTransactionsTotal[0].total
                      )}`}</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          ) : (
            <Typography sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}>
              Não existem Remessas para o cliente
            </Typography>
          )}
        </Box>
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ gridColumn: "span 4" }}
            >
              Beneficiários
            </Typography>
          </Box>

          {recipients.length > 0 ? (
            <TableContainer
              component={Paper}
              sx={{ gridColumn: "span 4", maxHeight: 200 }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <b>Nome</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Documento</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Banco 1</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Banco 2</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recipients.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.identificationDocument}</TableCell>
                      <TableCell>{row.bankName1}</TableCell>
                      <TableCell>{row.bankName2}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}>
              Não existem Beneficiários cadastrados para o cliente
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "20px",
          borderRadius: 3,
          autoComplete: "off",
          padding: 3,
          gap: 2,
          bgcolor: colors.primary[400],
          display: "grid",
          gridTemplateColumns: "repeat(8, 1fr)",
        }}
      >
        {isNonMobile && <Box sx={{ gridColumn: "span 6" }} />}

        <Button
          variant="contained"
          color="secondary"
          height="100%"
          onClick={handleClose}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 8" }}
        >
          <Typography fontWeight="bold">FECHAR</Typography>
        </Button>
      </Box>
    </Box>
  );
};
export default ClientMiniReport;
