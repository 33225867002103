import { Box, Button, Typography, useMediaQuery
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {GridToolbarQuickFilter, DataGrid , GridActionsCellItem} from "@mui/x-data-grid";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { tokens } from "../../theme.js";
import NewRecpientDialog from "../../components/NewRecipientDialog.js"
import EditRecipientDialog from "../../components/EditRecipientDialog.js";
import DeleteRecipientDialog from "../../components/DeleteRecipientDialog.js";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../api/useAxiosPrivate.js";
import Toast from "../../components/Snackbar.js";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/NoRowsOverlay.js";
import { styled } from "@mui/material/styles";


const RECIPIENT_URL = "/recipients/";

const Recipients = () => {

    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const axiosPrivate = useAxiosPrivate()

    const [loadingData, setLoadingData] = useState(true) 
    const [recipients, setRecipients] = useState([])
    const [recipientInfo, setRecipientInfo] = useState({})
    const [clients, setClients] = useState([])
    
    const isNonMobile = useMediaQuery("(min-width:600px)");


    const [openNewRecipientDialog, setOpenNewRecipientDialog] = useState(false)
    const [openEditRecipientDialog, setOpenEditRecipientDialog] = useState(false)
    const [openDeleteRecipientDialog, setOpenDeleteRecipientDialog] = useState(false)

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [severity, setSeverity] = useState("");

    useEffect(() => {
      const getRecipients = async () => {
        try {
          const response = await axiosPrivate.get(RECIPIENT_URL);
          setRecipients(response.data);
          setLoadingData(false);
        } catch (err) {}
      };
  
      getRecipients();
    }, [openNewRecipientDialog, openEditRecipientDialog, openDeleteRecipientDialog]);

    useEffect(() => {
        const getClients = async () => {
          try {
            const response = await axiosPrivate.get("/clients");
            setClients(response.data);
          } catch (err) {}
        };
    
        getClients();
      }, []);

    const newRecipient = () =>{ 
        setOpenNewRecipientDialog(true)
       }


    const handleClose = () => {
        setOpenNewRecipientDialog(false)
        setOpenEditRecipientDialog(false)
        setOpenDeleteRecipientDialog(false)
    }

    const handleEditClick = (id) => () => {
      setRecipientInfo(recipients.find(recipient => recipient.id === id))
      setOpenEditRecipientDialog(true)
    };

    const handleDeleteClick = (id) => () => {
      setRecipientInfo(recipients.find(recipient => recipient.id === id))
      setOpenDeleteRecipientDialog(true)
    }

  
    const columns = [
        {
            field: "name",
            headerName:"Nome",
            flex: 1,
            cellClassName: "name-column--cell"},
        
        {
            field: "identificationDocument",
            headerName:"Documento",
            flex: 1
        },
        
        {
            field: "client",
            headerName: "Cliente",
            flex: 1,
            valueGetter: (params) => {return params.row.client.name},
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Opções',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
  
          
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      }
    ]

    const ColorButton = styled(Button)(() => ({
      color: "white",
      backgroundColor: "#E35A01",
      "&:hover": {
        backgroundColor: "#e97b34",
      },
    }));

    function QuickSearchToolbar() {
        return (
          <Box
            sx={{
              p: 0.5,
              pb: 1,
              display: 'flex',
              gap: "10px"
            }}
          >
            
            <GridToolbarQuickFilter placeholder="Busque pelo nome do Beneficiário ou Cliente" variant="outlined" style={{flex: .5}}/>
            <ColorButton
              variant="contained"
              onClick={newRecipient}
              sx={{ gridColumn: "span 2" }}
            >
              <AddCircleOutlinedIcon sx={{mr: "10px"}} />
              <Typography fontWeight="bold">NOVO</Typography>
            </ColorButton>
          </Box>
        );
      }
      
    return (
        
        <Box height="75vh" sx={{
            "& .MuiDataGrid-root": {
                border: "none"
            },
            "& .MuiDataGrid-cell": {
                borderBottom: "none"
            },
            "& .name-column--cell": {
                color: "#0c1c3c"
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#E35A01",
                borderBottom: "none",
                color: "white"
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400]
            },
            "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#fff",
                
            }
            }}>
      <DataGrid
        loading={loadingData}
        getRowId={(row) => row.id}
        columns={columns}
        rows={recipients}
        rowThreshold={0}
        slots={{ toolbar: QuickSearchToolbar, loadingOverlay: LinearProgress, noRowsOverlay: CustomNoRowsOverlay
           }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}

                    initialState={{pagination: {paginationModel: {pageSize: 25}}, }} pageSizeOptions={[25, 50, 100]}

        disableColumnMenu        
        
      />
      <Toast
          open={openAlert}
          alertMessage={alertMessage}
          setOpen={setOpenAlert}
          severity={severity}
        />
      
       <NewRecpientDialog
          openNewRecipientDialog={openNewRecipientDialog}
          handleClose={handleClose}
          clients={clients}
          recipients={recipients}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}/>

<EditRecipientDialog
          openEditRecipientDialog={openEditRecipientDialog}
          handleClose={handleClose}
          clients={clients}
          recipientInfo={recipientInfo}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}/>
          
    <DeleteRecipientDialog
          openDeleteRecipientDialog={openDeleteRecipientDialog}
          handleClose={handleClose}
          clients={clients}
          recipientInfo={recipientInfo}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}/>
       </Box>
    )

}

export default Recipients;