import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ClientMiniReport from "../scenes/Clients/clientMiniReport";

export default function ClientMiniReportDialog(props) {
  const { openClientMiniReportDialog, handleClose, clientInfo } = props;

  return (
    <div>
      <Dialog
        open={openClientMiniReportDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <ClientMiniReport clientInfo={clientInfo} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
