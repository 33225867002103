import { useContext, useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import { storeContext } from "../context/storeContext";
import { useTheme, Autocomplete, TextField } from "@mui/material";
import { tokens } from "../theme";


const SelectStore = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {stores} = props
  
  const [storesList, setStoresList] = useContext(storeContext)
  

  return (

    <Autocomplete
      sx={{ m: 1, width: 500 }}
      multiple
      value={storesList}
      options={stores}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        setStoresList(newValue);
      }}

      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Selecionar Lojas"
          placeholder="Add Loja"
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          key={option.id}
          value={option}
          sx={{ justifyContent: "space-between" }}
        >
          {option.name}
        </MenuItem>
      )}
    />

    );
        
}

export default SelectStore;
