import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme";

const StatBox = ({ title, subtitle, icon, increase }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    return (
        <Box width="100%" m="15px 30px">
            <Box display="flex" justifyContent="space-between">
                <Box>
                    {icon}      
                </Box>
                    <Typography variant="h4" fontWeight="bold" sx={{color: colors.greenAccent[500]}}>
                        {title}
                    </Typography>
            </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5" fontWeight="bold" sx={{color: "#0c1c3c"}}>
                        {subtitle}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="left" marginTop="10px">
                    <Typography variant="h6" sx={{color: "#0c1c3c"}}>
                        {increase}  
                    </Typography>
                </Box>
            </Box>
    )
};

export default StatBox;
