import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Image from "../register/Design-sem-nome.jpg";

function PricingPage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          backgroundColor={colors.secondary}
        >
          <Box
            sx={{
              display: "flex",
              my: 8,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <stripe-pricing-table
              pricing-table-id="prctbl_1MmTdBF6RgoI6gH4TTaRnYqY"
              publishable-key="pk_test_51MQvqIF6RgoI6gH40T2JBIXB5PF6BtCb2gcU7cdi1xrgDvzQzs5N2NvKFS1DYeJXRHR72Qgfrvbk0BHcdXm9gxlb0023Pn60rS"
              customer-sesion-client-secret={
                "cuss_secret_PfG2epwa4KzEcxv8ivoDSqxx6Ys8h2Vn5nacMp5a4jOr4EJ"
              }
              customer-email="teste@testepayment.com"
              //      client-reference-id={user.id}
            ></stripe-pricing-table>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default PricingPage;
