import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import EditClient from "../scenes/Clients/editClient";

export default function EditClientDialog(props) {
  const {
    openEditClientDialog,
    handleClose,
    clientInfo,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
  } = props;

  return (
    <div>
      <Dialog
        open={openEditClientDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <EditClient
            clientInfo={clientInfo}
            handleClose={handleClose}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
